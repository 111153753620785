import React from 'react';
import styled from 'styled-components';

import euImage from './logo-erasmus_en-1.png';
import udLearningLabImage from './UD-LearningLab.png';

const FooterA = styled.footer`
    background-color: #f5f5f5;
    padding: 10px 0;
    text-align: center;
    font-size: 14px;
    color: #999;
    border-top: 1px solid #e5e5e5;
    /* two columns */
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
`;

export default function Footer() {
    return (
        <FooterA>
            <div

            >
                <img src={euImage} alt="European Union program"
                    style={{ width: '300px', height: 'auto' }} />
            </div>

            <div>
                <img src={udLearningLabImage} alt="University of Deusto Learning Lab"
                    style={{ width: '300px', height: 'auto' }} />
            </div>
        </FooterA>
    );
}