import React from "react";
import styled from "styled-components";
import { useMutation } from "@apollo/client";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Button as MuiButton,
} from "@material-ui/core";

import { REPORT_USER } from "graphql/user";
import { useGlobalMessage } from "hooks/useGlobalMessage";

const Button = styled.button`
  height: 27px;
  cursor: pointer;
  outline: none;
  font-size: ${(p) => p.theme.font.size.xxs};
  font-weight: ${(p) => p.theme.font.weight.bold};
  transition: background-color 0.2s, border-color 0.1s;
  border-radius: ${(p) => p.theme.radius.sm};
  color: ${(p) => p.theme.colors.white};
  padding: ${(p) => p.theme.spacing.xxs} ${(p) => p.theme.spacing.xs};
  border: ${(p) => "0"};
  background-color: ${() => "#f4964c"};

  &:hover {
    border-color: ${(p) => p.theme.colors.border.dark};
  }
`;

export default function ReportProfile({ user }) {
  const [open, setOpen] = React.useState(false);
  const [reason, setReason] = React.useState("");
  const [reportUser] = useMutation(REPORT_USER);

  const message = useGlobalMessage();

  const handleSubmit = async () => {
    await reportUser({
      variables: {
        input: {
          userId: user.id,
          reason,
        },
      },
    });

    message.success("Report submitted");

    setOpen(false);
  };

  React.useEffect(() => {
    if (open) {
      setReason("");
    }
  }, [open]);

  return (
    <>
      <Dialog aria-labelledby="profile-report-dialog-title" open={open}>
        <DialogTitle id="profile-report-dialog-title">
          Report {user.fullName}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter the reason for reporting this user
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="report-reason"
            label="Reason"
            type="text"
            fullWidth
            multiline
            value={reason}
            onChange={(e) => setReason(e.target.value)}
          />
        </DialogContent>

        <DialogActions>
          <MuiButton onClick={() => setOpen(false)} color="primary">
            Cancel
          </MuiButton>
          <MuiButton
            onClick={handleSubmit}
            color="primary"
            disabled={reason.length === 0}
            variant="contained"
          >
            Submit report
          </MuiButton>
        </DialogActions>
      </Dialog>

      <Button onClick={() => setOpen(true)}>Report</Button>
    </>
  );
}
