import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import { blue } from "@material-ui/core/colors";
import { DialogActions } from "@material-ui/core";
import { A } from "components/Text";
import styled from "styled-components";
import { generatePath } from "react-router-dom";
import * as Routes from "routes";

const StyledA = styled(A)`
  display: block;

  &:hover {
    background-color: ${(p) => p.theme.colors.grey[100]};
  }
`;

const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
});

function ProfileFollowersDialog(props) {
  const classes = useStyles();
  const { user, open, onClose } = props;

  return (
    <Dialog aria-labelledby="profile-followers-dialog-title" open={open}>
      <DialogTitle id="profile-followers-dialog-title">
        {user.fullName} followers
      </DialogTitle>
      <List>
        {user.followers.map(
          ({ id, follower: { username, fullName, image } }) => (
            <StyledA
              key={id}
              to={generatePath(Routes.USER_PROFILE, { username })}
              onClick={onClose}
            >
              <ListItem>
                <ListItemAvatar>
                  <Avatar className={classes.avatar} src={image} />
                </ListItemAvatar>
                <ListItemText primary={fullName} />
              </ListItem>
            </StyledA>
          )
        )}
      </List>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ProfileFollowersDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

export default function ProfileFollowers({ user }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        variant="text"
        color="inherit"
        onClick={handleClickOpen}
        style={{ textTransform: "none" }}
      >
        <span>
          <b>{user.followers.length}</b> followers
        </span>
      </Button>
      <ProfileFollowersDialog user={user} open={open} onClose={handleClose} />
    </>
  );
}
